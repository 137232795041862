import React from 'react'
import classes from './Content.module.css'
import Button from '../Button/Button'
import { CheckIconCircle } from '../icons'

const iconColor = "#4B53BC" // primary color

export default () => (
    <div style={{ backgroundColor: '#F6F6F6'}} className={classes.WhatYouGetContainer}>
        <div  style={{maxWidth: 1170, padding: '40px 20px', margin: 'auto', color: '#333'}}>
            <div style={{textAlign: 'center'}}>
                <h2>Pourquoi le Psy-Scan Institut?</h2>
            </div>
            <div className={classes.HowItWorks}>
                <div className={classes.HowItWorksItem}>
                    <div style={{maxWidth: 48, marginBottom: 10}}>
                        <CheckIconCircle width="44" height="44" fill={iconColor}/>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        Les thérapeutes ont un <strong>minimum</strong> de <strong>3 ans d’expérience</strong>, sont <strong>accrédités</strong> et <strong>autorisés</strong> à pratiquer en privé.
                    </div>
                </div>
                <div className={classes.HowItWorksItem}>
                    <div style={{maxWidth: 48, marginBottom: 10}}>
                        <CheckIconCircle width="44" height="44" fill={iconColor}/>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        Nos thérapeutes satisfont à des critères de <strong>sélection rigoureux</strong> et sont <strong>sélectionnés</strong> pour vous assurer le meilleur soutien.
                    </div>
                </div>
                <div className={classes.HowItWorksItem}>
                    <div style={{maxWidth: 48, marginBottom: 10}}>
                        <CheckIconCircle width="44" height="44" fill={iconColor}/>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        Nous prenons de nouveaux patients. Si vous nous <strong>contactez maintenant</strong> vous obtiendrez <strong>un rendez-vous rapidement</strong>.
                    </div>
                </div>
            </div>
            <div style={{textAlign: 'center', marginTop: 20}}>
                <Button color="primary" text="PRENDRE UN RENDEZ-VOUS"/>
                <p style={{marginTop: 10}}>ou appelez au <a href="tel:+41327214933"><strong>032 721 49 33</strong></a></p>
            </div>
        </div>


    </div>
)