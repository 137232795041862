import React, {Component} from 'react'
import Carousel from '../components/Carousel/Carousel'
import Press from '../components/Press/Press'
import NeuchatelPage from '../components/Contents/Home/NeuchatelPage'
import Therapies from '../components/Contents/Neuchatel/Therapies'
import WhatYouGet from '../components/Contents/WhatYouGet'
import MoreInfoSection from '../components/Contents/Home/MoreInfo'
import FooterAds from '../components/Footer/FooterAds'
import HeroSection from '../components/HeroSection/Hero'
import MobileActions from '../components/MobileActions/MobileActions'
import HowItWorks from '../components/Contents/Home/HowIsWorks'
import Helmet from 'react-helmet'
import Layout from '../layouts'

export default class extends Component {
    state = {
        isMobile: false,
        windowWidth: null,
        isLoading: true,
    }

    componentDidMount() {
        this.setState({
            isMobile: window.innerWidth < 768,
            windowWidth: window.innerWidth,
            isLoading: false
        }, () => {
            window.addEventListener("resize", this.updateDeviceState)
        })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDeviceState)
    }

    updateDeviceState = () => this.setState({ isMobile: window.innerWidth < 768, windowWidth: window.innerWidth})

    render() {

        return (
            <Layout location={this.props.location}>
                <Helmet>
                    <title>Psychiatre et Psychologue à Neuchatel | Psy-Scan Institute</title>
                    <meta name="description" content="Groupe de psychiatre et de psychologues à Neuchâtel offrant des prises en charge de psychiatrie et psychothérapie. Nous acceptons de nouveaux patients."/>
                </Helmet>
                <HeroSection {...this.state } {...this.props} city="Neuchâtel"/>
                <Carousel/>
                {/* <Journals/> */}
                <NeuchatelPage/>
                <WhatYouGet />
                <Press/>
                <Therapies/>
                <HowItWorks/>
                <MoreInfoSection isMobile={this.state.isMobile} { ...this.props }/>
                <FooterAds {...this.props}/>
                {this.props.scrollPosition > 600
                    ? <MobileActions/>
                    : null
                }
            </Layout>
        )

    }
}


