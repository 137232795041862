import React from 'react'
import classes from './Neuchatel.module.css'
import IndImage from '../../../images/therapie-individuelle-image.jpg'
import FamImage from '../../../images/therapie-familiale.jpg'
import Reseau from '../../../images/school_working-with-school-mid-year-meeting_article-2986_parent-teacher-conference-3-e1506520463165.jpg'
import TeleImage from '../../../images/telepsychiatry.jpg'

export default () => (
    <div style={{backgroundColor: '#F6F6F6'}} className={classes.TherapiesContainer}>
        <div style={{maxWidth: 1170, margin: 'auto', padding: '40px 15px'}}>
            <div style={{textAlign: 'center', maxWidth: 800, margin: 'auto'}}>
                <h2>On est à votre écoute</h2>
                <h3 style={{fontWeight: 300}}>
                    Nos thérapeutes travaillent de manière confidentielle et adaptent leur approche à vos besoins individuels.
                </h3>
            </div>
            <div className={classes.TherapiesWrapper}>
                <div className={classes.TherapiesItem}>
                    <div style={{
                        minHeight: 200,
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${IndImage})`
                    }} />

                    <div className={classes.TherapyContent}>
                        <h3 className={classes.TherapiesTitle}>INDIVIDUELLE</h3>
                        <p>
                            La thérapie individuelle vise à traiter les troubles émotionnels, relationnels et psychologiques de l’individu. Cette prise en charge est disponible aux enfants de tout âge, aux ados et aux adultes.
                        </p>
                    </div>
                </div>

                <div className={classes.TherapiesItem}>
                    <div style={{
                        minHeight: 200,
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${FamImage})`
                    }} />
                    <div className={classes.TherapyContent}>
                        <h3 className={classes.TherapiesTitle}>COUPLE ET FAMILLE</h3>
                        <p>
                            La thérapie de couple et familiale s’adresse aux couples, mariés ou non, et aux personnes confrontées aux problèmes de la vie commune. Elle vise à établir un fonctionnement plus stable et plus équilibré.
                        </p>
                    </div>
                </div>

                <div className={classes.TherapiesItem}>
                    <div style={{
                        minHeight: 200,
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${Reseau})`
                    }} />
                    <div className={classes.TherapyContent}>
                        <h3 className={classes.TherapiesTitle}>RÉSEAUX TRANSDISCIPLINAIRE</h3>
                        <p>
                            Nous travaillerons aussi en étroite collaboration avec les professionnels et les institutions nécessaires à la résolution de vos difficultés (par exemple les écoles, l’AI, le système judiciaire, etc.).
                        </p>
                    </div>
                </div>

                <div className={classes.TherapiesItem}>
                    <div style={{
                        minHeight: 200,
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${TeleImage})`
                    }} />

                    <div className={classes.TherapyContent}>
                        <h3 className={classes.TherapiesTitle}>TÉLÉPSYCHIATRIE</h3>
                        <p>
                            Ce service de télépsychiatrie permet à ceux qui ont des difficultés de déplacement dues aux trajets ou autres, l’accès à un psychologue ou un psychiatre en ligne à l’aide de systèmes de vidéoconférence.
                        </p>
                    </div>
                </div>

            </div>
            {/*<div className={classes.CallToActionTherapy}>*/}
                {/*<Button color="primary" text="PRENDRE UN RENDEZ-VOUS"/>*/}
                {/*<p style={{marginTop: 10}}>ou appelez <a href="tel:+41327214933"><strong>032 721 49 33</strong></a></p>*/}
            {/*</div>*/}
        </div>
    </div>
)